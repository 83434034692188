import {
  createRouter,
  /* createWebHashHistory */ createWebHistory,
} from 'vue-router'
import { auth } from './before_each/permission'
import { accessMethod } from './before_each/access_method'
import { unlimitedAccess } from './before_each/unlimited_access'
import { closeLoading, showLoading } from './before_each/show_loading'

export const orgDefaultIndex = '/'
export const orgDefaultPrefix = '/org'
export const shopDefaultIndex = '/shop'
export const shopDefaultPrefix = '/shop'
export const shopConfig = '/shop/shopConfig'

/**
 * 公共路由表
 */
const publicRoutes = [
  {
    path: '/login',
    name: 'LoginView',
    component: () => import('@/views/login/LoginView'),
  },
  {
    path: '/register',
    name: 'RegisterView',
    component: () => import('@/views/register/RegisterView'),
  },
  {
    path: '/privacyPolicy',
    name: 'PrivacyPolicyView',
    component: () => import('@/views/register/PrivacyPolicyView'),
  },
  {
    path: '/userProtocol',
    name: 'UserProtocolView',
    component: () => import('@/views/register/UserProtocolView'),
  },
  {
    path: '/technicalSupport',
    name: 'technicalSupport',
    component: () => import('@/views/user-docs/TechnicalSupportView.vue'),
  },
  {
    path: '/404',
    name: '404View',
    component: () => import('@/views/error-page/404View'),
  },
  {
    path: '/401',
    name: '401View',
    component: () => import('@/views/error-page/401View'),
  },
  {
    path: '/tmp',
    name: 'TmpView',
    component: () => import('@/views/tmp/TmpView'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  // history: createWebHashHistory(),
  routes: publicRoutes,
  scrollBehavior(to, from, savedPosition) {
    // 始终滚动到顶部
    return { top: 0 }
  },
})

// 前置路由守卫
router.beforeEach(auth)
router.beforeEach(accessMethod)
router.beforeEach(unlimitedAccess)

// 放最后
router.beforeEach(showLoading)
router.afterEach(closeLoading)

// 解决跳转页面滚动条消失的问题
router.afterEach((to, from, next) => {
  document
    .querySelector('body')
    .setAttribute('style', 'overflow: auto !important;')
})

export default router
