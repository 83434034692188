import store from '@/store'
import { getItem } from '@/utils/localStorage'
import { SHOP_ID } from '@/constant'

// 获取拥有的所有权限
export const getAllPerm = () => {
  const permArr = []
  // 组织权限
  permArr.push(...getOrgPerm())
  // 店铺权限
  permArr.push(...getCurrentShopPerm())
  return permArr
}

// 获取拥有的组织权限
export const getOrgPerm = () => {
  const permArr = []
  // 组织权限
  if (store.getters.permission.orgPerm) {
    permArr.push(...store.getters.permission.orgPerm.resourcePermissions)
    permArr.push(...store.getters.permission.orgPerm.apiPermissions)
  }
  return permArr
}

// 获取拥有的当前店铺权限
export const getCurrentShopPerm = () => {
  const permArr = []
  // 店铺权限
  if (store.getters.permission[getItem(SHOP_ID)]) {
    permArr.push(
      ...store.getters.permission[getItem(SHOP_ID)].resourcePermissions,
    )
    permArr.push(...store.getters.permission[getItem(SHOP_ID)].apiPermissions)
  }
  return permArr
}

// #region 判断组织权限
// 判断组织权限是否存在
export const isOrgPerm = (perm) => {
  const existPermArr = getOrgPerm()
  return existPermArr.includes(perm)
}
// #endregion

// #region 判断店铺权限
// 判断店铺权限是否存在
export const isShopPerm = (perm) => {
  const existPermArr = getCurrentShopPerm()
  return existPermArr.includes(perm)
}

// 判断店铺权限是否存在任意一个
export const isShopAnyPerm = (permArr) => {
  const existPermArr = getCurrentShopPerm()
  return permArr.some((item) => existPermArr.includes(item))
}

// 判断店铺权限是否全都存在
export const isShopAllPerm = (permArr) => {
  const existPermArr = getCurrentShopPerm()
  return permArr.every((item) => existPermArr.includes(item))
}
// #endregion
