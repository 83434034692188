import request from '@/utils/request'

/**
 * 获取线上订单列表
 * @returns 线上订单列表列表
 */
export const getOnlineOrderList = (params) => {
  return request({
    url: '/shop/onlineOrder/getOnlineOrderList',
    method: 'GET',
    params,
  })
}

/**
 * 获取线上订单详情
 */
export const getOnlineOrderDetail = (id) => {
  return request({
    url: `/shop/onlineOrder/getOnlineOrderDetail/${id}`,
    method: 'GET',
  })
}

/**
 * 手动备货
 */
export const manualStocking = (orderId) => {
  return request({
    url: `/shop/onlineOrder/manualStocking/${orderId}`,
    method: 'POST',
  })
}

/**
 * 确认收货/确认送达
 */
export const confirmReceipt = (data) => {
  return request({
    url: '/shop/onlineOrder/confirmReceipt',
    method: 'POST',
    data,
  })
}

/**
 * 根据ID获取线上订单 Macou 抵扣详情列表
 */
export const getMacouUseList = (orderId) => {
  return request({
    url: `/shop/onlineOrder/getMacouUseList/${orderId}`,
    method: 'GET',
  })
}

/**
 * 根据ID获取线上订单 Macou 返币详情列表
 */
export const getMacouRebateList = (orderId) => {
  return request({
    url: `/shop/onlineOrder/getMacouRebateList/${orderId}`,
    method: 'GET',
  })
}

/**
 * 根据订单ID获取线上订单Sku信息
 */
export const getSkuInfoByOrderId = (id) => {
  return request({
    url: `/shop/onlineOrder/getSkuInfoByOrderId/${id}`,
    method: 'GET',
  })
}

/**
 * 线上订单申请退款
 */
export const requestRefund = (data) => {
  return request({
    url: '/shop/onlineOrder/requestRefund',
    method: 'POST',
    data,
  })
}

/**
 * 获取保障服务列表
 */
export const guaranteeServiceList = (orderId) => {
  return request({
    url: `/shop/onlineOrder/guaranteeServiceList/${orderId}`,
    method: 'GET',
  })
}

/**
 * 订单状态跟踪
 */
export const getStatusTracking = (orderId) => {
  return request({
    url: `/shop/onlineOrder/getStatusTracking/${orderId}`,
    method: 'GET',
  })
}

/**
 * 判断订单时间是否过期
 */
export const checkOrderTimeExpire = (orderId) => {
  return request({
    url: `/shop/onlineOrder/checkOrderTimeExpire/${orderId}`,
    method: 'GET',
  })
}

/**
 * 发起退款后查看线上订单状态是否成功
 */
export const getRefundOrder = (id) => {
  return request({
    url: `/shop/onlineOrder/getRefundOrder/${id}`,
    method: 'GET',
  })
}

/**
 * 获取可用并且带有打票机的收银台
 */
export const getAvailableAndPrintCashierCounterList = () => {
  return request({
    url: '/shop/onlineOrder/getAvailableAndPrintCashierCounterList',
    method: 'GET',
  })
}

/**
 * 打印线上订单小票
 */
export const printOnlineTicket = (data) => {
  return request({
    url: '/shop/onlineOrder/printOnlineTicket',
    method: 'POST',
    data,
  })
}

/**
 * 打印线上退款订单小票
 */
export const printOnlineRefundTicket = (data) => {
  return request({
    url: '/shop/onlineOrder/printOnlineRefundTicket',
    method: 'POST',
    data,
  })
}

/**
 * 根据退款ID获取退款详情
 */
export const getRefundDesById = (id) => {
  return request({
    url: `/shop/onlineOrder/getRefundDesById/${id}`,
    method: 'GET',
  })
}

/**
 * 获取线上订单的提示备货时间
 */
export const getOnlineOrderPickingTime = (id) => {
  return request({
    url: `/shop/onlineOrder/getOnlineOrderPickingTime/${id}`,
    method: 'GET',
  })
}

/**
 * 获取线上收款金额信息
 */
export const getOnlineAmountCollected = (id) => {
  return request({
    url: `/shop/onlineOrder/getOnlineAmountCollected/${id}`,
    method: 'GET',
  })
}

/**
 * 获取线上实际收款金额详情
 */
export const getOnlineActualAmountReceived = (id) => {
  return request({
    url: `/shop/onlineOrder/getOnlineActualAmountReceived/${id}`,
    method: 'GET',
  })
}
