<template>
  <el-dropdown
    trigger="click"
    class="international"
    @visible-change="loadShopList"
    @command="switchShop"
  >
    <div style="display: flex">
      <span class="font-size-20 font-weight-700" style="margin-right: 5px">{{
        getItem(SHOP).shopName
      }}</span>
      <el-icon class="font-size-20"><ArrowDownBold /></el-icon>
    </div>
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item
          v-for="item in shopList"
          :key="item"
          :command="item"
          :disabled="getItem(SHOP).id === item.id"
        >
          {{ item.shopName }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script setup>
import { SHOP } from '@/constant'
import { getItem } from '@/utils/localStorage'
import { enterTheShop } from '@/utils/shop'
import { loadStore } from '@/utils/store'
import { reactive } from 'vue'

// const store = useStore()
// const i18n = useI18n()

const shopList = reactive([])
const loadShopList = (show) => {
  if (!show) {
    return
  }
  shopList.splice(0, shopList.length)
  loadStore('org/getShopStatusInBusiness').then((data) => {
    if (data) {
      shopList.splice(0, shopList.length)
      data.forEach((item) => {
        shopList.push(item)
      })
    }
  })
}

const switchShop = (command) => {
  enterTheShop(command, true)
}
</script>

<style lang="scss" scoped></style>
