import { SHOP, SHOP_ID } from '@/constant'
import { setItem } from '@/utils/localStorage'
import { ElLoading } from 'element-plus'
// import { loadStore } from './store'
import i18n from '@/i18n'
import router from '@/router'
import store from '@/store'
import { loadStoreNoLoading } from './store'

/**
 * 进入店铺的功能
 *
 * @param {*} shop 店铺信息
 * @param {*} refresh 是否要刷新
 * @param {*} domain 店铺自定义域名，比如：aaaaaaaa
 */
export const enterTheShop = async (shop, refresh) => {
  // 删除所有 Tag
  store.commit('app/removeAllTagsView')
  // 设置店铺 ID
  setItem(SHOP_ID, shop.id)
  setItem(SHOP, shop)
  const loading = ElLoading.service({
    lock: true,
    text: i18n.global.t('msg.storeSelect.openTheShop'),
  })
  await loadStoreNoLoading('user/getShopPerm', shop.id)
  loading.close()

  if (refresh) {
    window.location.href = store.getters.shopDefaultIndex
  } else {
    router.push(store.getters.shopDefaultIndex)
  }
}
