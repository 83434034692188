import { LANG } from '@/constant'
import { jsonBig } from './json'

export const setItem = (key, value) => {
  if (typeof value === 'object') {
    value = JSON.stringify(value)
  }

  window.localStorage.setItem(key, value)
}

export const getItem = (key) => {
  const data = window.localStorage.getItem(key)
  try {
    return jsonBig.parse(data)
  } catch (err) {
    return data
  }
}

export const removeItem = (key) => {
  window.localStorage.removeItem(key)
}

/**
 * 至少保留国际化
 */
export const removeAllItem = (keepKeys = [LANG]) => {
  const keepVal = {}
  if (keepKeys) {
    keepKeys.forEach((key) => {
      if (getItem(key)) {
        keepVal[key] = getItem(key)
      }
    })
  }

  window.localStorage.clear()

  for (const val in keepVal) {
    setItem(val, keepVal[val])
  }
}
