import {
  getGoodsStockList,
  getGoodsStockSkuListById,
  getGoodsStockById,
  changeGoodsInStock,
  changeGoodsOutStock,
  getRawMaterialStockList,
  changeRawMaterialInStock,
  changeRawMaterialOutStock,
  getRawMaterialStockById,
  stockWarnList,
  skuList,
  stockWarnPurcList,
  generatePurcOrder,
  stockWarnListSetting,
  skuSettingList,
  setting,
  getSkuWarehouseList,
  getSkuWarehouseStockRecordList,
  moveWarehouse,
  getSkuShelfList,
  getSkuShelfStockRecordList,
  getRawWarehouseList,
  getRawWarehouseStockRecordList,
  rawMoveWarehouse,
  rawMaterTracking,
  getCardStockList,
  getCardWarehouseList,
  cardMoveWarehouse,
  getCardWarehouseStockRecordList,
  getCardShelfList,
  getCardShelfStockRecordList,
  getAiTime,
  getStockSkuDetail,
  getLockNumList,
  getOnTheWayNumList,
  getStockRawDetail,
  getRawLockNumList,
  getRawOnTheWayNumList,
  getCardStockDetail,
  getCardLockNumList,
  getCardOnTheWayNumList,
} from '@/api/shop/stock'

export default {
  namespaced: true,
  state: () => ({
    stockList: [],
  }),
  mutations: {
    // 首页库存预警跳转时使用
    setStockWarningFlag(state, flag) {
      // flag, 1: 商品库存预警。2：原材料库存预警
      state.stockWarningFlag = flag
    },
  },
  actions: {
    async getGoodsStockList(context, params) {
      return getGoodsStockList(params)
    },
    async getGoodsStockSkuListById(context, id) {
      return await getGoodsStockSkuListById(id)
    },
    async getGoodsStockById(context, params) {
      return await getGoodsStockById(params)
    },
    async changeGoodsInStock(context, data) {
      return await changeGoodsInStock(data)
    },
    async changeGoodsOutStock(context, data) {
      return await changeGoodsOutStock(data)
    },
    async getRawMaterialStockList(context, params) {
      return getRawMaterialStockList(params)
    },
    async changeRawMaterialInStock(context, data) {
      return await changeRawMaterialInStock(data)
    },
    async changeRawMaterialOutStock(context, data) {
      return await changeRawMaterialOutStock(data)
    },
    async getRawMaterialStockById(context, params) {
      return await getRawMaterialStockById(params)
    },
    async stockWarnList(context, params) {
      return await stockWarnList(params)
    },
    async skuList(context, params) {
      const id = params.id
      delete params.id
      return await skuList(id, params)
    },
    async stockWarnPurcList(context, params) {
      return await stockWarnPurcList(params)
    },
    async generatePurcOrder(context, data) {
      return await generatePurcOrder(data)
    },
    async stockWarnListSetting(context, params) {
      return await stockWarnListSetting(params)
    },
    async skuSettingList(context, params) {
      const id = params.id
      delete params.id
      return await skuSettingList(id, params)
    },
    async setting(context, data) {
      return await setting(data)
    },
    async getSkuWarehouseList(context, id) {
      return await getSkuWarehouseList(id)
    },
    async getSkuWarehouseStockRecordList(context, params) {
      return await getSkuWarehouseStockRecordList(params)
    },
    async moveWarehouse(context, data) {
      return await moveWarehouse(data)
    },
    async getSkuShelfList(context, id) {
      return await getSkuShelfList(id)
    },
    async getSkuShelfStockRecordList(context, params) {
      return await getSkuShelfStockRecordList(params)
    },
    async getRawWarehouseList(context, id) {
      return await getRawWarehouseList(id)
    },
    async getRawWarehouseStockRecordList(context, params) {
      return await getRawWarehouseStockRecordList(params)
    },
    async rawMoveWarehouse(context, data) {
      return await rawMoveWarehouse(data)
    },
    async rawMaterTracking(context, params) {
      return await rawMaterTracking(params)
    },
    async getCardStockList(context, params) {
      return getCardStockList(params)
    },
    async getCardWarehouseList(context, id) {
      return await getCardWarehouseList(id)
    },
    async cardMoveWarehouse(context, data) {
      return await cardMoveWarehouse(data)
    },
    async getCardWarehouseStockRecordList(context, params) {
      return await getCardWarehouseStockRecordList(params)
    },
    async getCardShelfList(context, id) {
      return await getCardShelfList(id)
    },
    async getCardShelfStockRecordList(context, params) {
      return await getCardShelfStockRecordList(params)
    },
    async getAiTime(context, params) {
      return await getAiTime(params)
    },
    async getStockSkuDetail(context, params) {
      return await getStockSkuDetail(params)
    },
    async getLockNumList(context, params) {
      return await getLockNumList(params)
    },
    async getOnTheWayNumList(context, params) {
      return await getOnTheWayNumList(params)
    },
    async getStockRawDetail(context, params) {
      return await getStockRawDetail(params)
    },
    async getRawLockNumList(context, params) {
      return await getRawLockNumList(params)
    },
    async getRawOnTheWayNumList(context, params) {
      return await getRawOnTheWayNumList(params)
    },
    async getCardStockDetail(context, params) {
      return await getCardStockDetail(params)
    },
    async getCardLockNumList(context, params) {
      return await getCardLockNumList(params)
    },
    async getCardOnTheWayNumList(context, params) {
      return await getCardOnTheWayNumList(params)
    },
  },
}
