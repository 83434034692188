import request from '@/utils/request'

/**
 * 获取自提订单列表详情
 */
export const getSelfPickOrderListDes = (params) => {
  return request({
    url: '/shop/order/getSelfPickOrderListDes',
    method: 'GET',
    params,
  })
}

/**
 * 操作自提订单
 */
export const operSelfPickOrder = (data) => {
  return request({
    url: '/shop/order/operSelfPickOrder',
    method: 'POST',
    data,
  })
}

/**
 * 获取自提订单第几次打印小票
 */
export const getSelfPickPrintNum = (id) => {
  return request({
    url: `/shop/order/getSelfPickPrintNum/${id}`,
    method: 'GET',
  })
}

/**
 * 根据ID获取自提订单详情
 */
export const getSelfPickOrderDesById = (id) => {
  return request({
    url: `/shop/order/getSelfPickOrderDesById/${id}`,
    method: 'GET',
  })
}

/**
 * 操作自提订单状态
 */
export const operSelfPickOrderStatus = (data) => {
  return request({
    url: '/shop/order/operSelfPickOrderStatus',
    method: 'POST',
    data,
  })
}

/**
 * 获取配送订单列表详情
 */
export const getDeliveryOrderListDes = (params) => {
  return request({
    url: '/shop/order/getDeliveryOrderListDes',
    method: 'GET',
    params,
  })
}

/**
 * 操作配送订单
 */
export const operDeliveryOrder = (data) => {
  return request({
    url: '/shop/order/operDeliveryOrder',
    method: 'POST',
    data,
  })
}

/**
 * 获取配送订单第几次打印小票
 */
export const getDeliveryPrintNum = (id) => {
  return request({
    url: `/shop/order/getDeliveryPrintNum/${id}`,
    method: 'GET',
  })
}

/**
 * 根据ID获取配送订单详情
 */
export const getDeliveryOrderDesById = (id) => {
  return request({
    url: `/shop/order/getDeliveryOrderDesById/${id}`,
    method: 'GET',
  })
}

/**
 * 操作配送订单状态
 */
export const operDeliveryOrderStatus = (data) => {
  return request({
    url: '/shop/order/operDeliveryOrderStatus',
    method: 'POST',
    data,
  })
}

/**
 * 获取线下订单列表详情
 */
export const getOfflineOrderListDes = (params) => {
  return request({
    url: '/shop/order/getOfflineOrderListDes',
    method: 'GET',
    params,
  })
}

/**
 * 根据ID获取线下订单详情
 */
export const getOfflineOrderDesById = (id) => {
  return request({
    url: `/shop/order/getOfflineOrderDesById/${id}`,
    method: 'GET',
  })
}

/**
 * 获取线下商品列表
 */
export const getOfflineGoods = (params) => {
  return request({
    url: '/shop/order/getOfflineGoods',
    method: 'GET',
    params,
  })
}

/**
 * 获取费率
 */
export const getRate = () => {
  return request({
    url: '/shop/order/getRate',
    method: 'GET',
  })
}

/**
 * 获取分类
 */
export const getGoodsClassify = () => {
  return request({
    url: '/shop/order/getGoodsClassify',
    method: 'GET',
  })
}

/**
 * 已收款
 */
export const paid = (data) => {
  return request({
    url: '/shop/order/paid',
    method: 'POST',
    data,
  })
}

/**
 * 发起收款
 */
export const startPayment = (data) => {
  return request({
    url: '/shop/order/startPayment',
    method: 'POST',
    data,
  })
}

/**
 * 获取计算价格
 */
export const showTotalPrice = (data) => {
  return request({
    url: '/shop/order/showTotalPrice',
    method: 'POST',
    data,
  })
}

/**
 * 发起收款后查看订单状态是否成功
 */
export const getPlaceOrder = (id) => {
  return request({
    url: `/shop/order/getPlaceOrder/${id}`,
    method: 'GET',
  })
}

/**
 * 发起退款
 */
export const requestRefund = (data) => {
  return request({
    url: '/shop/order/requestRefund',
    method: 'POST',
    data,
  })
}

/**
 * 发起退款后查看订单状态是否成功
 */
export const getRefundOrder = (id) => {
  return request({
    url: `/shop/order/getRefundOrder/${id}`,
    method: 'GET',
  })
}

/**
 * 根据ID获取线下订单打印信息
 */
export const getOfflineOrderPrintInfo = (params) => {
  return request({
    url: '/shop/order/getOfflineOrderPrintInfo',
    method: 'GET',
    params,
  })
}

/**
 * 根据订单ID获取订单Sku信息
 */
export const getSkuInfoByOrderId = (id) => {
  return request({
    url: `/shop/order/getSkuInfoByOrderId/${id}`,
    method: 'GET',
  })
}

/**
 * 判断订单时间是否过期
 */
export const checkOrderTimeExpire = (id) => {
  return request({
    url: `/shop/order/checkOrderTimeExpire/${id}`,
    method: 'GET',
  })
}

/**
 * 根据ID获取线下订单详情V2
 */
export const getOfflineOrderDesByIdV2 = (id) => {
  return request({
    url: `/shop/order/v2/getOfflineOrderDesById/${id}`,
    method: 'GET',
  })
}

/**
 * 根据退款ID获取退款详情
 */
export const getRefundDesById = (id) => {
  return request({
    url: `/shop/order/getRefundDesById/${id}`,
    method: 'GET',
  })
}

/**
 * 打印小票
 */
export const printTicket = (data) => {
  return request({
    url: '/shop/order/printTicket',
    method: 'POST',
    data,
  })
}

/**
 * 根据SKU ID获取对应的商品保障服务列表
 */
export const getGuaranteeServicesListOnSku = (id) => {
  return request({
    url: `/shop/order/getGuaranteeServicesListOnSku/${id}`,
    method: 'GET',
  })
}

/**
 * 获取保障服务列表
 */
export const guaranteeServiceList = (id) => {
  return request({
    url: `/shop/order/guaranteeServiceList/${id}`,
    method: 'GET',
  })
}

/**
 * 根据ID获取线下订单 Macou 抵扣详情列表
 */
export const getMacouUseList = (id) => {
  return request({
    url: `/shop/order/getMacouUseList/${id}`,
    method: 'GET',
  })
}

/**
 * 根据ID获取线下订单 Macou 返利详情列表
 */
export const getMacouRebateList = (id) => {
  return request({
    url: `/shop/order/getMacouRebateList/${id}`,
    method: 'GET',
  })
}

/**
 * 打印退款小票
 */
export const printRefundTicket = (data) => {
  return request({
    url: '/shop/order/printRefundTicket',
    method: 'POST',
    data,
  })
}

/**
 * 获取线下收款金额信息
 */
export const getOfflineAmountCollected = (id) => {
  return request({
    url: `/shop/order/getOfflineAmountCollected/${id}`,
    method: 'GET',
  })
}

/**
 * 根据会员码查询会员的macou余额
 */
export const getMacouBalance = (code) => {
  return request({
    url: `/shop/order/getMacouBalance/${code}`,
    method: 'GET',
  })
}

/**
 * 获取支付方式详情
 */
export const getPaymentManyDetail = (id) => {
  return request({
    url: `/shop/order/getPaymentManyDetail/${id}`,
    method: 'GET',
  })
}

/**
 * 获取可以抵扣的值
 */
export const showDeductionValue = (data) => {
  return request({
    url: '/shop/order/showDeductionValue',
    method: 'POST',
    data,
  })
}

/**
 * 根据ID获取线下订单退款 Macou 抵扣详情列表
 */
export const getRefundMacouUseList = (id) => {
  return request({
    url: `/shop/order/getRefundMacouUseList/${id}`,
    method: 'GET',
  })
}

/**
 * 根据ID获取线下订单退款 Macou 返利详情列表
 */
export const getRefundMacouRebateList = (id) => {
  return request({
    url: `/shop/order/getRefundMacouRebateList/${id}`,
    method: 'GET',
  })
}

/**
 * 获取线下实际收款金额详情
 */
export const getOfflineActualAmountReceived = (id) => {
  return request({
    url: `/shop/order/getOfflineActualAmountReceived/${id}`,
    method: 'GET',
  })
}
