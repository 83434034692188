export const TOKEN = 'token'
// 国际化
export const LANG = 'language'
// Tag 视图
export const TAGS_VIEW = 'tagsView'
// 当前选择的收银台
export const CURRENT_CASHIER = 'currentCashier'
// Shop ID
export const SHOP_ID = 'shopId'
// Shop
export const SHOP = 'shop'
// Shop Header key
export const SHOP_ID_HEADER_KEY = 'Shop-Id'
// 当前过道和湾
export const CURRENT_AISLE_BAY = 'currentAisleBay'
// 会员码前缀
export const MASSHO_PREFIX = 'MEMBER-'
// 未知图片
export const UNKNOW_IMAGE = '/massho/unknow.jpg'
// 图片找不到
export const NOT_IMAGE = '/massho/notImage.jpg'
// 会话时间
export const SESSION_TIME = 'st'
// 从哪个页面跳到了 Settings 页面
export const SETTINGS_ORIGINAL_PAGE = 'settingsOriginalPage'

// 标记：是否打开过 面包屑这点 引导窗口
export const FLAG_OPEN_HAMBURGER_FOLD_BOOT = 'FLAG_OPEN_HAMBURGER_FOLD_BOOT'
